<template>
  <div class="overlay-detail-panel" id="overlay-customer">
    <div
      class="scorecard-wrapper"
      v-click-outside="() => $emit('closePanel', scorecard)"
    >
      <div class="scorecard-content">
        <div class="user-info-wrapper">
          <v-row class="flex justify-space-between">
            <h4>
              {{ obsUser.name }}
            </h4>
            <div
              v-if="haveAccessToEdit"
              class="button"
              style="margin: 0 2px"
              @click="editScorecard"
            >
              <img
                src="../../assets/images/edit.svg"
                alt=""
                style="height: 16px; width: 16px"
              />
              <p class="button-text">
                {{ $t("Edit") }}
              </p>
            </div>
          </v-row>
          <p>{{ year }} {{ mappedMonth }}</p>
        </div>
        <div v-if="!loaded">
          <div
            style="margin-top: 24px; position: absolute; top: 30%; left: 50%"
            class="loader"
          />
        </div>
        <div
          v-if="template && template.fields && loaded"
          class="scorecard-form-body"
          :key="scorecardUpdater"
        >
          <div class="scorecard-form-header content-small">
            <span style="width: 64px; margin-left: 108px">
              {{ $t("UnitPrice") }}
            </span>
            <span style="width: 260px">
              {{ $t("Activity") }}
            </span>
            <span style="width: 70px">
              {{ $t("Quantity") }}
            </span>
            <span style="width: 48px">
              {{ $t("Points") }}
            </span>
          </div>
          <div
            v-for="field in template.fields"
            :key="field.id"
            class="topic-row"
          >
            <span class="topic-title">
              {{ field.title }}
            </span>
            <div
              v-for="criteria in field.criteria"
              :key="criteria.value"
              class="topic-field-row"
            >
              <span class="field-unit-price">
                {{
                  criteria.points
                    ? criteria.points
                    : criteria.thresholds
                    ? calculatePointsRange(criteria.thresholds)
                    : 0
                }}
              </span>
              <span class="field-unit-activity">
                <span class="content-normal" style="z-index: 100">
                  <v-tooltip bottom v-if="showDetails(criteria)">
                    <template v-slot:activator="{ on }">
                      <span class="hovered-span" v-on="on">{{
                        criteria.name
                      }}</span>
                    </template>
                    <span
                      v-html="showDetails(criteria)"
                    ></span>
                  </v-tooltip>
                  <span v-else>{{ criteria.name }}</span>
                </span>
                <div
                  class="field-unit-add-widget-comment"
                  v-if="criteria.commenting"
                >
                  <img
                    src="@/assets/images/chat-no-bubble.svg"
                    style="width: 20px; height: 20px"
                    alt=""
                  />
                  <div
                    v-if="
                      !editComment[field._id + '_' + criteria._id] &&
                      !criteria.comment
                    "
                    @click="() => openEditComment(field, criteria)"
                  >
                    <p class="link">
                      +
                      {{
                        $t("addComment").charAt(0).toUpperCase() +
                        $t("addComment").slice(1)
                      }}
                    </p>
                  </div>

                  <textarea
                    v-click-outside="() => closeEditComment(field, criteria)"
                    v-if="editComment[field._id + '_' + criteria._id]"
                    v-model="criteria.comment"
                    ref="commentbox"
                    id="textarea"
                    style="white-space: pre-wrap"
                    class="task-textarea content-normal"
                    rows="5"
                  />
                  <div
                    v-if="
                      criteria.comment &&
                      !editComment[field._id + '_' + criteria._id]
                    "
                    @click="() => openEditComment(field, criteria)"
                    class="content-normal comment-block"
                  >
                    {{ criteria.comment }}
                  </div>
                </div>
              </span>
              <span v-if="edit && criteria.editable">
                <v-text-field
                  v-model="criteria.userQuantity"
                  outlined
                  type="number"
                  class="field-unit-price"
                  @input="calculateMonthSummary"
                />
              </span>
              <span v-else class="field-number">
                {{ criteria.userQuantity ? formatNumber(criteria) : 0 }}
              </span>
              <span class="field-number">
                {{ calculateTotal(criteria) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="scorecard-footer">
        <p>{{ $t("Total") }}: {{ monthSummary }}</p>
        <button
          v-if="edit"
          @click="save"
          class="transparent-button"
          style="background: black !important; color: white"
        >
          <img
            src="../../assets/images/checkmarkCircled.svg"
            style="height: 16px; width: 16px"
            alt=""
          />
          <span>
            {{ $t("Save") }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment/moment";
import axios from "axios";

export default {
  name: "scorecardSidepanel",
  props: ["userScorecards", "obsUser", "year", "month"],
  components: {},
  data: () => ({
    template: null,
    widgetPosition: null,
    viewLink: false,
    obsLinks: [],
    edit: false,
    monthSummary: 0,
    scorecards: null,
    scorecard: null,
    scorecardUpdater: 0,
    editComment: {},
    isFocused: {},
    loaded: false,
  }),
  async created() {
    this.scorecards = this.userScorecards;
    await this.loadUserScorecard();
  },
  computed: {
    ...mapGetters(["scorecardTemplate", "user", "userScorecard"]),
    mappedMonth() {
      return moment(this.month, "M").format("MMMM");
    },
    haveAccessToEdit() {
      return (
        this.user.isAdmin ||
        (this.user.isGroupAdmin &&
          this.obsUser.groups.some((g) =>
            this.user.groups.some((g2) => g.name === g2.name)
          ))
      );
    },
  },
  methods: {
    ...mapActions(["setUserScorecards"]),
    showDetails(criteria) {
      if (criteria.value === "monthlyRevenue") {
        return [
          "kuu käive üle 50 000 EUR - 150 punkti",
          "kuu käive üle 40 000 EUR - 120 punkti",
          "kuu käive üle 30 000 EUR - 100 punkti",
          "kuu käive üle 20 000 EUR - 80 punkti",
          "kuu käive üle 10 000 EUR - 60 punkti",
          "kuu käive üle 8 000 EUR - 55 punkti",
          "kuu käive üle 6 500 EUR - 50 punkti",
          "kuu käive üle 5 000 EUR - 40 punkti",
          "kuu käive üle 3 500 EUR - 30 punkti",
          "kuu käive üle 2 500 EUR - 20 punkti",
          "kuu käive üle 2 000 EUR - 4 punkti",
          "kuu käive üle 1 500 EUR - 3 punkti",
          "kuu käive üle 1 000 EUR - 2 punkti",
        ].join("<br>");
      }
      return null;
    },
    async loadUserScorecard() {
      this.loaded = false;
      const scorecardResponse = await axios.get(
        `/api/scorecard/user/${this.obsUser._id}/${this.year}/${this.month}`
      );
      const templateResponse = await axios.get(`/api/scorecard/template`);
      if (scorecardResponse.status === 200 && templateResponse.status === 200) {
        const scorecard = scorecardResponse.data[0];
        const template = templateResponse.data[0];
        template.fields.forEach((field) => {
          field.criteria.forEach((criteria) => {
            const score = scorecard.scores.find(
              (s) => s.value === criteria.value
            );
            if (score.comment) {
              criteria.comment = score.comment;
            }
            if (score) {
              criteria.userPoints = score.points;
              criteria.userQuantity = score.quantity;
            } else {
              criteria.userPoints = 0;
              criteria.userQuantity = 0;
            }
          });
        });

        this.monthSummary = scorecard.monthlySummary || 0;
        this.scorecardSubmitted = scorecard.submitted;

        this.template = template;
        this.scorecardDeadline = template.deadline;
        this.loaded = true;
      } else {
        console.error("Failed to fetch scorecard or template");
      }
    },
    async closeEditComment(field, criteria) {
      let uniqueKey = field._id + "_" + criteria._id;
      if (this.editComment[uniqueKey] !== undefined) {
        this.editComment[uniqueKey] = false;
      }
      await this.save();
    },
    openEditComment(field, criteria) {
      let uniqueKey = field._id + "_" + criteria._id;
      this.$set(this.editComment, uniqueKey, true);
    },
    async save() {
      let transformedScores = this.template.fields.flatMap((field) =>
        field.criteria.map((criteria) => ({
          id: criteria._id,
          value: criteria.value,
          points: criteria.userPoints,
          quantity: criteria.userQuantity,
          comment: criteria.comment,
        }))
      );

      let payload = {
        scores: transformedScores,
        monthlySummary: this.monthSummary,
      };

      let response = await axios.post(
        `/api/scorecard/update-scorecard/${this.obsUser._id}/${this.obsUser.access.company._id}/${this.year}/${this.month}`,
        { userScorecard: payload }
      );

      if (response.status === 200) {
        this.toastSuccess(
          this.$t(this.submitted ? "ScorecardSubmitted" : "ScorecardSaved")
        );
        this.edit = false;
        this.$emit("update-month-summary", {
          userId: this.obsUser._id,
          monthSummary: this.monthSummary,
          month: this.month,
        });
      } else {
        this.toastError(this.$t("Error"));
      }
    },
    calculateMonthSummary() {
      this.monthSummary = this.template.fields.reduce((totalSum, field) => {
        return (
          totalSum +
          field.criteria.reduce((criteriaSum, criteria) => {
            const total = this.calculateTotal(criteria);
            return criteriaSum + Number(total);
          }, 0)
        );
      }, 0);
      this.monthSummary = Math.round(this.monthSummary * 100) / 100;
    },
    calculatePointsRange(thresholds) {
      if (!thresholds || thresholds.length === 0) {
        return null;
      }

      let minPoints = thresholds[0].points;
      let maxPoints = thresholds[0].points;

      thresholds.forEach((threshold) => {
        if (threshold.points < minPoints) {
          minPoints = threshold.points;
        }
        if (threshold.points > maxPoints) {
          maxPoints = threshold.points;
        }
      });

      return `${minPoints}-${maxPoints}`;
    },
    editScorecard() {
      this.edit = !this.edit;
    },
    formatTotal(number) {
      const decimalsArray = number.toString().split(".");
      if (decimalsArray[1] && decimalsArray[1].length >= 2) {
        number = number.toFixed(2);
      }
      return number;
    },
    calculateTotal(field) {
      if (field.editable) {
        let total = field.points * field.userQuantity;

        if (field && field.maxPoints) {
          const maxTotal = field.maxPoints;
          if (total > maxTotal) {
            total = maxTotal;
          }
        }
        field.userPoints = total;
        return this.formatTotal(field.userPoints);
      } else {
        return Number(field.userPoints) || 0;
      }
    },
    formatNumber(value) {
      let parts = value.userQuantity.toString().split(".");
      let integerPart = parts[0];
      let decimalPart = parts.length > 1 ? "." + parts[1] : "";

      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      let formattedNumber = integerPart + decimalPart;
      return value.suffix
        ? `${formattedNumber} ${value.suffix}`
        : formattedNumber;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

.scorecard-content {
  height: calc(100% - 104px);
  overflow-y: scroll;
}

.comment-block {
  border: 1px solid #e6e8ec;
  border-radius: 4px;
  background: $light-grey;
  padding: 4px 8px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.field-unit-add-widget-comment {
  display: flex;
  gap: 8px;
  margin-top: 8px;

  p {
    font-size: 14px;
    line-height: 20px;
  }

  &:hover {
    cursor: pointer;
  }
}

.hovered-span {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-underline-offset: 4px;
  }
}

.button {
  border: 1px solid $grey500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 24px;
  padding: 0 4px;
  cursor: pointer;
  gap: 4px;
  font-size: 12px;

  &:hover {
    background-color: #f4f5f7;
  }
}

.field-number {
  margin-top: 12px;
  width: 70px;
  text-align: center;
}

.field-link {
  text-decoration: underline;
  white-space: nowrap;
  align-self: start;
  line-height: 20px;
  cursor: pointer;
  gap: 8px;
}

.scorecard-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 640px;
  background: white;
  height: 104px;
  border-top: 1px solid $grey500;
  padding: 32px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 12px;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.user-info-wrapper {
  display: grid;
  padding: 40px 32px 32px 32px;
  border-bottom: 1px solid $grey500;
  gap: 8px;
}

.scorecard-form-body {
  padding: 32px 16px 32px 16px;
}

.topic-field-row {
  padding-left: 108px;
  margin: 8px 0;
  display: flex;
  gap: 24px;
}

.topic-title {
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  padding-left: 12px;
}

.field-unit-activity {
  width: 260px;
  margin-top: 12px;
}

.field-unit-price {
  width: 64px;
  max-width: 64px;
  //display: flex;
  margin-top: 12px;
}

.scorecard-form-header {
  padding: 4px 0 8px 0;
  border-bottom: 2px solid #000;
  gap: 24px;
}

.topic-row {
  padding: 12px 0;
  width: 100%;
  border-bottom: 1px solid $grey500;
}

.topic-row:last-of-type {
  border-bottom: 0;
}

.scorecard-wrapper {
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 7;
  position: fixed;
  width: 640px;

  border: 1px solid #e6e8ec;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.overlay-detail-panel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 101;
}
</style>
