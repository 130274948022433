<template>
  <div>
    <Header
        :activeView="activeView"
        :year="obsYear"
        :month="obsMonth"
        @searchInput="(val) => searchInput = val"
        @viewChanged="changeView"
        @monthChanged="(val) => obsMonth = val"
        @yearChanged="changeYear"
    />
    <Content
        :active-view="activeView"
        :search="searchInput"
        :month="month"
        :year="year"
        :obsYear="obsYear"
        :obsMonth="obsMonth"
    />
  </div>
</template>

<script>
import Header from "@/components/Scorecard/Header.vue";
import Content from "@/components/Scorecard/Content.vue";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import axios from "axios";
export default {
  name: "Scorecard",
  components: {
    Header,
    Content,
  },
  data() {
    return {
      year: moment().isBefore(moment("01.06", "MM.DD"))
          ? moment().add(-1, "year").year().toString()
          : moment().year().toString(),
      month: moment().isBefore(moment("06", "DD"))
          ? moment().add(-1, "month").format("M") - 0
          : moment().format("M") - 0,
      obsYear: null,
      obsMonth: null,
      startMonth: null,
      activeView: "Scorecard",
      searchInput: "",
    };
  },
  async created() {
    this.obsYear = this.year;
    this.obsMonth = this.month;
    if (!this.users) await this.getUsers();
    if (this.user.isAdmin || this.user.isGroupAdmin) this.activeView = "Users"
    await this.initData()
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["user", "users", "groups", "scorecardTemplate", "userScorecards"]),
  },
  methods: {
    ...mapActions(["setScorecardTemplate", "setUserScorecards", "getUsers", "getGroups"]),
    changeView(view) {
      this.activeView = view;
      if (this.activeView === "Scorecard") {
        this.obsYear = this.year
        this.obsMonth = this.month
      }
    },
    async initData() {
      const response = await axios.get(`/api/admin/scorecards/${this.user._id}`);
      if (response.status === 200) {
        await this.setUserScorecards(response.data)
      }
      const templateResponse = await axios.get(`/api/get-scorecard-template`);
      if (templateResponse.status === 200) {
        await this.setScorecardTemplate(templateResponse.data)
      }
      await this.getGroups()
    },
    changeYear(year) {
      this.obsYear = year;
      if (this.obsYear === moment().year().toString()) {
        this.obsMonth = this.month
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
