var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loaded ? _c('div', {
    attrs: {
      "id": "scorecard-scorecard-content"
    }
  }, [_c('div', {
    staticStyle: {
      "margin-bottom": "16px"
    }
  }, [_c('div', {
    staticStyle: {
      "font-size": "18px",
      "line-height": "24px",
      "display": "flex",
      "gap": "12px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("SubmitDate")) + " "), _vm.scorecardDeadline ? _c('div', {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.scorecardDeadline)) + " ")]) : _vm._e()]), _c('div', [_vm._v(" " + _vm._s(_vm.$t("Status")) + ": "), _vm.scorecardSubmitted ? _c('span', {
    staticStyle: {
      "color": "#0aaf60"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Submitted")) + " ")]) : _c('span', {
    staticStyle: {
      "color": "#ff1e24"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("NotSubmitted")) + " ")])])]), _c('div', {
    staticClass: "scorecard-form"
  }, [_c('div', {
    staticClass: "scorecard-form-header content-small"
  }, [_c('span', {
    staticStyle: {
      "width": "106px",
      "margin-left": "91px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("UnitPrice")) + " ")]), _c('span', {
    staticStyle: {
      "width": "408px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Activity")) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t("Quantity")) + " ")])]), _vm.template && _vm.template.fields ? _c('div', {
    staticClass: "scorecard-form-body"
  }, _vm._l(_vm.template.fields, function (field) {
    return _c('div', {
      key: field.id,
      staticClass: "topic-row"
    }, [_c('div', {
      staticClass: "topic-title"
    }, [_vm._v(" " + _vm._s(field.title) + " ")]), _vm._l(field.criteria, function (criteria) {
      return _c('div', {
        key: criteria.value
      }, [_c('div', {
        staticClass: "topic-field-row"
      }, [_c('div', {
        staticClass: "topic-field-row-content"
      }, [_c('span', {
        staticStyle: {
          "min-width": "56px",
          "height": "24px",
          "font-size": "18px",
          "line-height": "24px",
          "font-family": "Inter, sans-serif"
        }
      }, [_vm._v(_vm._s(criteria.points ? criteria.points : criteria.thresholds ? _vm.calculatePointsRange(criteria.thresholds) : 0) + " ")]), _c('div', {
        staticClass: "topic-field-row-content-info"
      }, [_c('div', {
        staticStyle: {
          "display": "flex",
          "flex-direction": "column",
          "gap": "8px",
          "width": "376px"
        }
      }, [_c('span', {
        staticClass: "content-normal",
        class: {
          'title-padding': !field.allowComments || _vm.deadlineExpired && !field.editComment && !field.editComment,
          'title-top-padding': field.allowComments && (field.editComment || field.comment || !_vm.deadlineExpired)
        }
      }, [_vm.showDetails(criteria) ? _c('v-tooltip', {
        attrs: {
          "right": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function (_ref) {
            var on = _ref.on;
            return [_c('span', _vm._g({
              staticClass: "hovered-span"
            }, on), [_vm._v(_vm._s(criteria.name))])];
          }
        }], null, true)
      }, [_c('span', {
        domProps: {
          "innerHTML": _vm._s(_vm.showDetails(criteria))
        }
      })]) : _c('span', [_vm._v(_vm._s(criteria.name))])], 1), criteria.commenting ? _c('div', {
        staticStyle: {
          "padding-bottom": "16px",
          "display": "flex",
          "flex-wrap": "nowrap"
        }
      }, [!_vm.editComment[field._id + '_' + criteria._id] && !criteria.comment ? _c('div', {
        staticStyle: {
          "display": "flex",
          "flex-wrap": "nowrap",
          "gap": "8px"
        },
        on: {
          "click": function ($event) {
            return _vm.openEditComment(field, criteria);
          }
        }
      }, [_c('img', {
        attrs: {
          "height": "16",
          "width": "16",
          "src": require('@/assets/images/chat-no-bubble.svg'),
          "alt": "icon"
        }
      }), _c('div', {
        staticClass: "link-small",
        staticStyle: {
          "font-weight": "600"
        }
      }, [_vm._v(" + " + _vm._s(_vm.$t("addComment").charAt(0).toUpperCase() + _vm.$t("addComment").slice(1)) + " ")])]) : _vm._e(), _vm.editComment[field._id + '_' + criteria._id] ? _c('div', {
        staticClass: "comment-text-area",
        staticStyle: {
          "display": "flex",
          "gap": "8px",
          "width": "100%"
        }
      }, [_c('img', {
        attrs: {
          "height": "16",
          "width": "16",
          "src": require('@/assets/images/chat-no-bubble.svg'),
          "alt": "icon"
        }
      }), _c('textarea', {
        directives: [{
          name: "click-outside",
          rawName: "v-click-outside",
          value: function () {
            return _vm.closeEditComment(field, criteria);
          },
          expression: "\n                          () => closeEditComment(field, criteria)\n                        "
        }, {
          name: "model",
          rawName: "v-model",
          value: criteria.comment,
          expression: "criteria.comment"
        }],
        ref: "commentBox",
        refInFor: true,
        staticClass: "task-textarea content-normal",
        attrs: {
          "id": "textarea",
          "rows": "5"
        },
        domProps: {
          "value": criteria.comment
        },
        on: {
          "focus": function ($event) {
            _vm.isFocused[field._id + '_' + criteria._id] = true;
          },
          "input": function ($event) {
            if ($event.target.composing) return;
            _vm.$set(criteria, "comment", $event.target.value);
          }
        }
      })]) : _vm._e(), !_vm.editComment[field._id + '_' + criteria._id] && criteria.comment ? _c('div', {
        staticClass: "comment-text-area",
        staticStyle: {
          "display": "flex",
          "gap": "8px",
          "width": "100%"
        }
      }, [_c('img', {
        attrs: {
          "height": "16",
          "width": "16",
          "src": require('@/assets/images/chat-no-bubble.svg'),
          "alt": "icon"
        }
      }), _c('div', {
        staticClass: "content-small comment-block",
        on: {
          "click": function ($event) {
            return _vm.openEditComment(field, criteria);
          }
        }
      }, [_vm._v(" " + _vm._s(criteria.comment) + " ")])]) : _vm._e()]) : _vm._e()]), _c('div', {
        staticStyle: {
          "display": "flex",
          "gap": "32px",
          "flex-wrap": "nowrap"
        }
      }, [_c('div', {
        staticStyle: {
          "width": "116px"
        }
      }, [!criteria.editable ? _c('span', {
        staticStyle: {
          "padding-left": "16px"
        }
      }, [_vm._v(" " + _vm._s(criteria.userQuantity ? _vm.formatNumber(criteria) : 0) + " ")]) : _c('div', {
        staticStyle: {
          "padding": "4px 12px 4px 4px",
          "display": "flex",
          "gap": "4px",
          "align-items": "center",
          "max-height": "52px"
        }
      }, [_c('v-text-field', {
        staticClass: "field-unit-price",
        attrs: {
          "outlined": "",
          "type": "number"
        },
        on: {
          "input": _vm.calculateMonthSummary
        },
        model: {
          value: criteria.userQuantity,
          callback: function ($$v) {
            _vm.$set(criteria, "userQuantity", $$v);
          },
          expression: "criteria.userQuantity"
        }
      }), _c('div', {
        staticClass: "value-update-arrows",
        staticStyle: {
          "display": "flex",
          "cursor": "pointer",
          "flex-direction": "column"
        }
      }, [_c('img', {
        attrs: {
          "height": "20",
          "width": "20",
          "src": require('@/assets/images/chevron-up.svg'),
          "alt": "inc"
        },
        on: {
          "click": function ($event) {
            return _vm.incrementField(criteria);
          }
        }
      }), _c('img', {
        attrs: {
          "height": "20",
          "width": "20",
          "src": require('@/assets/images/chevron-down.svg'),
          "alt": "dec"
        },
        on: {
          "click": function ($event) {
            return _vm.decrementField(criteria);
          }
        }
      })])], 1)]), _c('div', {
        staticStyle: {
          "display": "flex",
          "align-items": "center"
        }
      }, [_c('p', {
        staticClass: "content-semibold"
      }, [_vm._v(" " + _vm._s(_vm.calculateTotal(criteria)) + " ")])])])])])])]);
    })], 2);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "separator"
  }), _c('div', {
    staticClass: "buttons-container"
  }, [_c('div', {
    staticClass: "scorecard-month-summary"
  }, [_vm._v(" " + _vm._s(_vm.$t("Total")) + ": " + _vm._s(_vm.monthSummary) + " ")]), _c('div', {
    staticClass: "flex justify-end",
    staticStyle: {
      "gap": "8px"
    }
  }, [!_vm.deadlineExpired ? _c('button', {
    staticClass: "transparent-button",
    staticStyle: {
      "background": "black !important",
      "color": "white"
    },
    on: {
      "click": function ($event) {
        return _vm.save(false);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]) : _vm._e()])])])]) : _c('div', {
    staticClass: "loader",
    staticStyle: {
      "margin-top": "24px",
      "position": "absolute",
      "top": "30%",
      "left": "50%"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }